import React, { useEffect, useState } from 'react';
import '../App.css';
import { FaPlane, FaWallet } from 'react-icons/fa';
import OrderService from '../OrderService';
import { useNavigate } from 'react-router-dom';
import contentData from '../content.json';

const Home = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Name:', name);
        console.log('Phone:', phone);

        try {
            await OrderService.createOrder(name, phone, contentData);
            navigate('/thank_you');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', contentData.accentColor);
        document.documentElement.style.setProperty('--secondary-color', contentData.primaryColor);
    }, []);

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (<div className='Test'>
        <selection>
            <img className='headerImg' src={contentData.headerImage} />
        </selection>
        <selection>
            <div class="discount-box">
                <h2>ОФОРМИТЬ ЗАКАЗ СО СКИДКОЙ <span>{contentData.orderDiscount}</span></h2>
                <p>Акция действует до 30 Июня 2024 года</p>
                <div class="price">
                    <span class="old-price">{(contentData.price * 1.538).toFixed(2)} BYN</span>
                    <span class="new-price">{contentData.price} BYN</span>
                </div>
                <button onClick={() => handleScroll('payment')}>Заказать сейчас</button>
            </div>
        </selection>
        <selection>
            <div class="tech-block">
                <div class="tech-header">{contentData.techHeader}</div>
                <img src={contentData.techImage1} />
                <div class="tech-description">
                    <p>{contentData.techDescription1}</p>
                    <img src={contentData.techImage2} />
                    <p>{contentData.techDescription2}</p>
                </div>
            </div>
        </selection>
        <selection>
            <div class="discount-box">
                <h2>ОФОРМИТЬ ЗАКАЗ СО СКИДКОЙ <span>{contentData.orderDiscount}</span></h2>
                <p>Акция действует до 30 Июня 2024 года</p>
                <div class="price">
                    <span class="old-price">{(contentData.price * 1.538).toFixed(2)} BYN</span>
                    <span class="new-price">{contentData.price} BYN</span>
                </div>
                <button onClick={() => handleScroll('payment')}>Заказать сейчас</button>
            </div>
        </selection>
        <selection>
            <div class="delivery-box">
                <h2>ДОСТАВКА И ОПЛАТА</h2>
                <div>
                    <div class="info">
                        <div className='Image'>
                            <FaPlane className='Icon' />
                        </div>
                        <div>
                            <strong>Доставка</strong>
                            <p>БЫСТРАЯ ДОСТАВКА В ЛЮБУЮ ТОЧКУ БЕЛАРУСИ</p>
                        </div>
                    </div>
                    <div class="info">
                        <div className='Image'>
                            <FaWallet className='Icon' />
                        </div>
                        <div>
                            <strong>Никаких предоплат</strong>
                            <p>ВЫ ОПЛАЧИВАЕТЕ ТОВАР ТОЛЬКО ПО ФАКТУ ПОЛУЧЕНИЯ НА РУКИ</p>
                        </div>
                    </div>
                </div>
            </div>
        </selection>
        <selection id="payment">
            <div class="discount-box">
                <h2>ОФОРМИТЬ ЗАКАЗ СО СКИДКОЙ <span>{contentData.orderDiscount}</span></h2>
                <p>Акция действует до 30 Июня 2024 года</p>
                <div class="price">
                    <span class="old-price">{(contentData.price * 1.538).toFixed(2)} BYN</span>
                    <span class="new-price">{contentData.price} BYN</span>
                </div>
                <p>После оформления заказа наш менеджер перезвонит Вам для уточнения деталей заказа</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Введите ваше имя"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Введите ваш телефон"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Заказать сейчас</button>
                </form>
            </div>
        </selection>
        <selection>
            <div class="contact-box">
                <p><strong>Акция действует на всей территории Беларуси</strong></p>
                <p><strong>Время работы:</strong> пн-вс 09:00 - 21:00</p>
                <p><strong>ООО "Цитруском"</strong> УНП 193699866</p>
                <p>220046, г. Минск, а/я 46</p>
                <p><strong>Телефон:</strong> +375 (29) 330-44-93</p>
                <p><strong>E-mail:</strong> citrustele@mail.ru</p>
                <p><a href="/politics">Политика конфиденциальности</a></p>
                <p><a href="/agreement">Пользовательское соглашение</a></p>
                <p><a href="/dogovor_vozvrata">Договор возврата товара и денежных средств</a></p>
                <p><a href="/dostavka">Договор доставки</a></p>
            </div>
        </selection>
    </div>);
}

export default Home;